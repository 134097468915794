// ---------------------- COMMON ----------------------

.icon {
  cursor: pointer;
  transition: all 0.3s;
}

.icon:hover {
  box-shadow: 2px 0px 30px 5px #156b7b;
  background: #062930;
}

.project__github-icon.icon {
  border-radius: 50%;
}

.push-right {
  margin-left: auto;
}

.visible {
  visibility: visible;
  opacity: 1;
}

.invisible {
  visibility: hidden;
  opacity: 0;
}

// ---------------------- HEADER ----------------------

.header {
  width: 100%;
  background-color: #040714;
  padding: calc(0px + 60 * ((100vw - 768px) / (1920 - 768)));
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.header__about {
  color: #1e7d90;
  font-size: 22px;
  transition: all 0.3s;
}

.header__about:hover {
  text-shadow: 0 0 5px #1e7d90;
}

// ---------------------- PROJECT ----------------------

.project {
  width: 40vw;
  color: #1e7d90;
  letter-spacing: 0;
  padding: 10px;
  margin: auto;
  margin-top: 80px;
  margin-top: calc(0px + 40 * ((100vw - 768px) / (1920 - 768)));
  display: flex;
  flex-direction: column;
}

.project__title-wrapper {
  display: flex;
  align-items: flex-start;

  a {
    text-decoration: none;
    display: flex;
    align-items: flex-start;

    .project__title {
      cursor: pointer;
      color: #156b7b;
      font-size: 36px;
      transition: all 0.3s;
    }

    .project__title:hover {
      text-shadow: 0 0 5px #1e7d90;
    }

    .project__new-tab-icon {
      margin: 8px 0 0 10px;
    }
  }

  .project__github-icon {
    margin-top: 8px;
  }
}

.project__description {
  min-height: 60px;
  font-size: 22px;
  color: #156b7b;
  margin-top: 20px;
}

.project__tags {
  min-height: 60px;
  color: #1e7d90;
  font-family: Montserrat-Bold;
  font-size: 22px;
  font-weight: 800;
  margin-top: 20px;
}

// ---------------------- CAROUSEL ----------------------

.carousel {
  padding-top: 20px;
  padding-bottom: 160px;
  margin-top: 50px;
  perspective: 1600px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    flex: 0 0 auto;
  }

  transition: opacity 2s;

  figure {
    // border: 5px solid red;
    width: 40vw;
    height: 22.5vw;
    transform-style: preserve-3d;
    transition: transform 0.5s;

    div {
      // border: 5px solid green;
      width: 100%;
      height: 100%;
      border-radius: 15px;
      transition: all 0.3s;

      background-image: linear-gradient(
        0deg,
        rgba(4, 7, 20, 0) 0%,
        #040714 100%
      );

      &:not(:first-of-type) {
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    /* reflection */
    div::after {
      cursor: auto;
      content: "";
      width: inherit;
      height: inherit;
      position: absolute;
      bottom: -102%;
      transform: scaleY(-1);
      border-radius: 15px;
    }

    .project1,
    .project2,
    .project3,
    .project1::after,
    .project2::after,
    .project3::after {
      background-size: contain;
    }

    .project1 {
      background-image: linear-gradient(
          0deg,
          rgba(4, 7, 20, 0.3) 0%,
          rgba(4, 7, 20, 0.8) 100%
        ),
        url("/images/project-1.jpg");
    }

    .project1::after {
      background-image: linear-gradient(
          rgba(4, 7, 20, 1) 60%,
          rgba(4, 7, 20, 0.9) 100%
        ),
        url("/images/project-1.jpg");
    }

    .project2 {
      background-image: linear-gradient(
          0deg,
          rgba(4, 7, 20, 0.3) 0%,
          rgba(4, 7, 20, 0.8) 100%
        ),
        url("/images/project-2.jpg");
    }

    .project2::after {
      background-image: linear-gradient(
          rgba(4, 7, 20, 1) 60%,
          rgba(4, 7, 20, 0.9) 100%
        ),
        url("/images/project-2.jpg");
    }

    .project3 {
      background-image: linear-gradient(
          0deg,
          rgba(4, 7, 20, 0.3) 0%,
          rgba(4, 7, 20, 0.8) 100%
        ),
        url("/images/project-3.jpg");
    }

    .project3::after {
      background-image: linear-gradient(
          rgba(4, 7, 20, 1) 60%,
          rgba(4, 7, 20, 0.9) 100%
        ),
        url("/images/project-3.jpg");
    }

    /* glow */
    div.next:hover,
    div.prev:hover {
      cursor: pointer;
      box-shadow: 2px 0 30px -2px #1e7d90;
    }
  }
}

@media (max-width: 768px) {
  .app {
    display: none !important;
  }
}
