.app-mobile {
  color: #156b7b;
  padding: 115px 57px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .mobile-about {
    max-width: 218px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin: auto;
  }

  .mobile-linkedin-logo {
    margin-top: 48px;
  }

  .mobile-project {
    width: 100%;
    margin-top: 116px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .mobile-project__title-wrapper {
      a {
        color: #156b7b;
        text-decoration: none;
        display: flex;
        align-items: flex-start;
      }

      .mobile-project__title {
        font-size: 30px;
      }

      .mobile-project__new-tab-icon {
        margin: 5px 0 0 14px;
      }
    }

    .mobile-project__description {
      font-size: 16px;
      margin-top: 20px;
    }

    .mobile-project__tags {
      color: #1e7d90;
      font-family: Montserrat-Bold;
      font-size: 16px;
      font-weight: 800;
      margin-top: 20px;
    }

    .mobile-project__github-icon {
      margin-top: 20px;
    }

    .mobile-project__image {
      width: calc(100vw - 114px);
      height: calc((100vw - 114px) * 0.5625);
      background-size: contain;
      border-radius: 15px;
      margin-top: 20px;
    }

    .mobile-project__image--project1 {
      background-image: linear-gradient(
          0deg,
          rgba(4, 7, 20, 0.3) 0%,
          rgba(4, 7, 20, 0.8) 100%
        ),
        url("/images/project-1.jpg");
    }

    .mobile-project__image--project2 {
      background-image: linear-gradient(
          0deg,
          rgba(4, 7, 20, 0.3) 0%,
          rgba(4, 7, 20, 0.8) 100%
        ),
        url("/images/project-2.jpg");
    }

    .mobile-project__image--project3 {
      background-image: linear-gradient(
          0deg,
          rgba(4, 7, 20, 0.3) 0%,
          rgba(4, 7, 20, 0.8) 100%
        ),
        url("/images/project-3.jpg");
    }
  }
}

@media (min-width: 769px) {
  .app-mobile {
    display: none;
  }
}
