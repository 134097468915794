@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat-Bold;
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
}

* {
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #040714;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// ---------------------- TOOLTIP ----------------------

.tooltip {
  position: relative;
}

.tooltip:before {
  content: attr(data-text);
  position: absolute;

  /* vertically center */
  top: 50%;
  transform: translateY(-50%);

  /* move to right */
  left: 100%;
  margin-left: 25px;

  /* basic styles */
  width: 300px;
  padding: calc(10px + 10 * ((100vw - 768px) / (1920 - 768)));
  border-radius: 8px;
  color: #167d91;
  background: rgba(21, 107, 123, 0.15);
  text-align: center;

  /* visibility */
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s;
}

.tooltip:after {
  content: "";
  position: absolute;

  /* position tooltip correctly */
  left: 100%;
  margin-left: 5px;

  /* vertically center */
  top: 50%;
  transform: translateY(-50%);

  /* the arrow */
  border: 10px solid rgba(21, 107, 123, 0.15);
  border-color: transparent rgba(21, 107, 123, 0.15) transparent transparent;

  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s;
}
.tooltip:hover:before,
.tooltip:hover:after {
  visibility: visible;
  opacity: 1;
  text-shadow: none;
}
